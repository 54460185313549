<template>
  <div>
    <button v-if="hasWhatsAppNumbers" class="whatsapp-btn" @click="toggleWhatsAppMenu">
      <i class="bx bx-support"></i>
    </button>
    <div v-if="showWhatsAppMenu" class="whatsapp-options">
      <button v-if="ConfigEmpresa.chatbox_asesores" class="whatsapp-option"
        @click="openWhatsApp(ConfigEmpresa.chatbox_asesores)">
        WhatsApp Asesores
      </button>
      <button v-if="ConfigEmpresa.chatbox_atencion" class="whatsapp-option"
        @click="openWhatsApp(ConfigEmpresa.chatbox_atencion)">
        WhatsApp Atención a clientes
      </button>
      <button v-if="ConfigEmpresa.chatbox_tmk" class="whatsapp-option" @click="openWhatsApp(ConfigEmpresa.chatbox_tmk)">
        WhatsApp Telemarketing
      </button>
    </div>
    <button class="scroll-top-btn" @click="scrollTop" :class="{ 'visible': showScrollTopBtn }">
      <i class="bx bx-up-arrow-alt"></i> <!-- Icono para el botón de regreso al tope -->
    </button>
    <div class="footer-basic">
      <footer>
        <div class="social">
          <a v-for="red in sucursalesDataPrincipal.redes" :href="getRedSocialLink(red.redsocial, red.valor)"
            target="_blank">
            <i :class="getIconClass(red.redsocial)"></i>
          </a>
        </div>
        <!--<ul class="list-inline">
          <li class="list-inline-item"><a href="#">Home</a></li>
          <li class="list-inline-item"><a href="#">Services</a></li>
          <li class="list-inline-item"><a href="#">About</a></li>
          <li class="list-inline-item"><a href="#">Terms</a></li>
          <li class="list-inline-item"><a href="#">Privacy Policy</a></li>
        </ul>-->
        <p class="copyright">{{ ConfigEmpresa.Nombre }} &copy; {{ ConfigEmpresa.ano }}</p>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showScrollTopBtn: false, // Inicialmente oculto
      sucursalesDataPrincipal: {},
      ConfigEmpresa: {},
      showWhatsAppMenu: false,
    };
  },
  methods: {
    // Método para hacer scroll hacia arriba
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Desplazamiento suave
      });
    },
    // Método para mostrar u ocultar el botón flotante
    handleScroll() {
      if (window.scrollY > 100) {
        this.showScrollTopBtn = true;
      } else {
        this.showScrollTopBtn = false;
      }
    },
    sucursalescontacto() {
      axios.get(`/api/admin/contactosucursales`)
        .then(response => {
          // Manejar la respuesta del servidor
          //console.log('manejo de sucursal')
          //console.log(response)
          this.sucursalesDataPrincipal = response.data.principal
          this.ConfigEmpresa = response.data.ConfigEmpresa
        })
        .catch(error => {
          // Manejar el error en caso de que ocurra
          console.error("Error al actualizar la sucursal:", error);
        });
    },
    getRedSocialLink(redsocial, valor) {
      switch (redsocial) {
        case 'Messenger':
          return `https://m.me/${valor}`;
        case 'Facebook':
          return `https://www.facebook.com/${valor}`;
        case 'Instagram':
          return `https://www.instagram.com/${valor}`;
        case 'WhatsApp':
          const message = 'Hola me gustaría obtener más información';
          return `https://wa.me/${valor}?text=${encodeURIComponent(message)}`;
        case 'X': // Anteriormente Twitter
          return `https://x.com/${valor}`;
        case 'Youtube':
          return `https://www.youtube.com/channel/${valor}`;
        default:
          return '#'; // Enlace predeterminado si no se encuentra la red social
      }
    },
    // Método para obtener la clase del icono de la red social
    getIconClass(redsocial) {
      switch (redsocial) {
        case 'Messenger':
          return 'bx bxl-messenger'; // Reemplaza esto con la clase del icono de Messenger
        case 'Facebook':
          return 'bx bxl-facebook'; // Reemplaza esto con la clase del icono de Facebook
        case 'Instagram':
          return 'bx bxl-instagram'; // Reemplaza esto con la clase del icono de Instagram
        case 'WhatsApp':
          return 'bx bxl-whatsapp'; // Reemplaza esto con la clase del icono de WhatsApp
        case 'X':
          return 'bx bxl-twitter'; // Reemplaza esto con la clase del icono de X
        case 'Youtube':
          return 'bx bxl-youtube'; // Reemplaza esto con la clase del icono de X
        default:
          return ''; // Clase predeterminada si no se encuentra la red social
      }
    },
    toggleWhatsAppMenu() {
      const chatboxData = [
        this.ConfigEmpresa.chatbox_asesores,
        this.ConfigEmpresa.chatbox_atencion,
        this.ConfigEmpresa.chatbox_tmk,
      ];
      // Filtrar los datos válidos (cadenas no vacías y no null)
      const filledFields = chatboxData.filter(value => typeof value === "string" && value.trim() !== "");
      if (filledFields.length === 1) {
        const singleNumber = filledFields[0];
        this.openWhatsApp(singleNumber)
      } else if (filledFields.length > 1) {
        this.showWhatsAppMenu = !this.showWhatsAppMenu;
      }

    },
    openWhatsApp(phoneNumber) {
      const message = "¡Hola! Quisiera más información."; // Mensaje predefinido.
      const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      window.open(url, "_blank");
      this.showWhatsAppMenu = false; // Cierra el menú al seleccionar
    },
  },
  mounted() {
    this.sucursalescontacto()
    // Mostrar u ocultar el botón flotante al hacer scroll
    window.addEventListener('scroll', this.handleScroll);

  },
  computed: {
    hasWhatsAppNumbers() {
      // Verificar si al menos uno de los campos no es nulo ni está vacío
      return [
        this.ConfigEmpresa.chatbox_asesores,
        this.ConfigEmpresa.chatbox_atencion,
        this.ConfigEmpresa.chatbox_tmk,
      ].some(value => typeof value === "string" && value.trim() !== "");
    }
  },
  beforeDestroy() {
    // Eliminar el event listener cuando el componente es destruido
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
<style scoped>
.whatsapp-btn {
  position: fixed;
  bottom: 140px;
  /* Posicionado un poco más arriba que el scroll-top-btn */
  right: 20px;
  background-color: #25D366;
  /* Color oficial de WhatsApp */
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 28px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.whatsapp-btn:hover {
  transform: scale(1.1);
  /* Efecto de zoom al pasar el cursor */
}

.whatsapp-btn i {
  margin-top: 2px;
  /* Ajusta ligeramente el icono verticalmente */
}

.whatsapp-menu {
  position: fixed;
  bottom: 140px;
  /* Misma posición que antes */
  right: 20px;
  z-index: 1000;
}

.whatsapp-options {
  position: fixed;
  bottom: 200px; /* Ajusta para que quede justo encima del botón principal */
  right: 20px; /* Alineado con el botón de WhatsApp */
  z-index: 1001; /* Asegúrate de que esté encima de otros elementos */
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column; /* Para apilar los botones */
  gap: 10px; /* Espacio entre los botones */
}

.whatsapp-option {
  background-color: #25D366;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.whatsapp-option:hover {
  background-color: #1DA851; /* Un tono más oscuro para el hover */
}

.footer-basic {

  padding: 40px 0;
  background-color: #ffffff;
  color: #ff9900;
  bottom: 0;

}

.footer-basic ul {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footer-basic li {
  padding: 0 10px;
}

.footer-basic ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footer-basic ul a:hover {
  opacity: 1;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-basic .social>a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footer-basic .social>a:hover {
  opacity: 0.9;
}

.footer-basic .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}

.scroll-top-btn {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #ff9900;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
  display: none;
  /* Inicialmente oculto */
  transition: opacity 0.3s ease;
}

.scroll-top-btn.visible {
  display: block;
  /* Mostrar cuando visible */
  opacity: 1;
}

.scroll-top-btn i {
  margin-top: 3px;
  /* Ajusta la posición del icono verticalmente */
}
</style>